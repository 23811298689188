<template>
	<el-dialog
		:visible.sync="visible"
		:show-close="false"
		:close-on-click-modal="false"
		:close-on-press-escape="false"
		class="role-dialog"
		width="350px">
		<span slot="title" class="dialog-title">
			<span>{{title}}</span>
			<img src="@/assets/images/close.png" alt="" @click="visible = false">
		</span>
		<el-form :model="dataForm" :rules="dataRule" label-position="top" ref="dataForm">
			<el-form-item label="类别名称" prop="name">
                <el-input v-model="dataForm.name" placeholder="类别名称" maxlength="32"></el-input>
            </el-form-item>
			<el-form-item label="排序" prop="sort">
                <el-input v-model="dataForm.sort" placeholder="排序" maxlength="10"></el-input>
            </el-form-item>
			<el-form-item label="类别说明" prop="remark">
                <el-input v-model="dataForm.remark" placeholder="类别说明" maxlength="512"></el-input>
            </el-form-item>
        </el-form>
		<span slot="footer" class="dialog-footer">
			<el-button class="submit-btn" @click="submit">确定</el-button>
		</span>
	</el-dialog>
</template>
<script>
import { debounce } from '@/utils/debounce'
export default {
	data() {
		return {
			title: '新增数据类别',
			visible: false,
			submitUrl: 'sysDataDetailAdd',
			code: '',
			id: '',
			dataForm: {
				remark: '',
				sort: '',
				name: ''
			},
			dataRule: {
				name: [
					{required: true,message: '类别名称不能为空', trigger: "blur"}
				],
				sort: [
					{required: true,message: '排序不能为空', trigger: "blur"},
					{pattern: /^[0-9]*$/, message: '排序必须为整数'}
				]
			},
			currentName: ''
		}
	},
	methods: {
		init(data,code,currentName) {
			this.visible = true;
			this.code = code;
			this.currentName = currentName;
			this.$nextTick(() => {
				this.$refs.dataForm.resetFields();
				if(data == 'add') {
					this.title = '新增数据类别';
					this.submitUrl = 'sysDataDetailAdd';
				}else {
					this.title = '修改数据类别';
					this.id = data.id;
					this.dataForm.sort = data.sort;
					this.dataForm.name = data.name;
					this.dataForm.remark = data.remark;
					this.submitUrl = 'sysDataDetailUpdate';
				}
			})
		},
		submit:debounce(function() {
			if(this.visible == false) {
				return
			}
			// 新增
			if(this.submitUrl == 'sysDataDetailAdd') {
				var submitForm = {
					code: this.code,
					name: this.dataForm.name,
					sort: this.dataForm.sort,
					remark: this.dataForm.remark
				}
			}
			// 修改
			else {
				var submitForm = {
					id: this.id,
					code: this.code,
					name: this.dataForm.name,
					sort: this.dataForm.sort,
					remark: this.dataForm.remark
				}
			}
			this.$refs['dataForm'].validate(valid => {
				if (valid) {
					let that = this;
					that.$request.post(
						that.submitUrl,
						false,
						submitForm,
						function (r) {
							if (r.code == "0") {
								that.visible = false;
								that.$emit('getDetailDataList',that.code,that.currentName);
								that.$message.success('保存成功');
							} else {
								that.$message.error(r.msg);
							}
						}
					);
				} else {
					return false;
				}
			});
		},1000)
	}
}
</script>
<style lang="scss" scoped>
.dialog-title{
	display: block;
	text-align: center;
	span{
		color: #222;
		font-weight: 600;
		font-size: 20px;
	}
	img{
		width: 28px;
		float: right;
		cursor: pointer;
	}
}
.el-form-item{
	margin-bottom: 10px;
}
.submit-btn{
	width: 100%;
	background-color: #117FFC;
	color: #fff;
}
.role-dialog /deep/ .el-dialog{
	border-radius: 10px !important;
}
.role-dialog /deep/ .el-dialog__body{
	padding: 0 20px !important;
}
.role-dialog /deep/ .el-form-item__label{
	padding: 0 !important;
}
.role-dialog /deep/ .el-dialog__footer{
	padding: 20px 20px 40px !important;
}
</style>
